








































































































































import dayjs from 'dayjs'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref, Ref } from '@vue/composition-api'

import PanelAccordion from '@/components/ui/PanelAccordion.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import { AdresseEan, authorizationTypes, GenericObject } from '@/inc/types'
import BlockTips from '@/components/ui/BlockTips.vue'
import * as check from '@/composables/commune'
import AdresseSwitcher from '@/components/commune/components/AdresseSwitcher.vue'
import {
  cadran,
  convertRegister,
  convertUnit,
  trimzerosIndex,
} from '@/inc/utils'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { groupArrayOfObjects } from '@/inc/utils/utils'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'commune-meter-history',
  components: {
    PanelAccordion,
    Breadcrumb,
    CustomLink,
    BlockTips,
    AdresseSwitcher,
  },

  setup(_, ctx) {
    const indexData: Ref<GenericObject[]> = ref([])
    const { $route } = ctx.root
    const { ean, compteur } = $route.params
    const myState = {
      ...useState('my', ['i18n']),
    }
    const communeState = {
      ...useState('commune', ['i18n']),
    }
    const breadcrumbPage = [
      {
        label: communeState.i18n.value.communeIndex.title?.replace(
          /<[^>]*>/g,
          ''
        ),
        url: { name: 'CommuneIndex' },
      },
      {
        label: 'Details compteur',
        url: {
          name: 'CommuneDetailsCompteur',
          params: { ean, compteur },
        },
      },
      {
        label: 'Historique',
        url: '',
      },
    ]
    const adresses: Ref<AdresseEan[]> = ref([])
    const { authorizations } = useGetters('user', ['authorizations'])
    const { getEans } = useGetters('commune', ['getEans'])
    const { FETCH_EANS } = useActions('commune', ['FETCH_EANS'])
    const exportDatas: Ref<any[]> = ref([])
    const releveIndexUrl = ref(
      `${VUE_APP_DOMAIN}/i?e=${ean}&c=${compteur}&tr=an&commune=true`
    )
    const isLoading: Ref<boolean> = ref(false)
    const activePanels: Ref<number[]> = ref([])

    const loadDatas = (codeEan: string) => {
      isLoading.value = true
      axios
        .get(`${getApiUrl()}/town/index/historique/${codeEan}`)
        .then(async response => {
          indexData.value = await response.data
          indexData.value.forEach(releve => {
            exportDatas.value.push({
              date: releve.date,
              index: releve.index,
              unite: releve.unite,
              numCpt: `'${releve.numCpt}'`,
              cadran: releve.cadran,
            })
          })
          const datas = groupArrayOfObjects(indexData.value, 'numCpt')
          indexData.value = datas
          isLoading.value = false
        })
        .catch(error => {
          console.error(error)
          isLoading.value = false
        })
    }

    onBeforeMount(() => {
      isLoading.value = true
      adresses.value = getEans.value
      if (!adresses.value || adresses.value.length === 0) {
        FETCH_EANS().then(async () => {
          adresses.value = await getEans.value
        })
      }
      loadDatas(ean)
    })

    const changeMeter = async data => {
      await ctx.root.$router.push({
        name: 'CommuneIndexHistorique',
        params: {
          ean: data.ean,
          compteur: data.meter,
        },
      })
      loadDatas(data.ean)
    }

    const togglePanel = (panel: number) => {
      if (activePanels.value.indexOf(panel) >= 0) {
        activePanels.value.splice(activePanels.value.indexOf(panel), 1)
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
      } else {
        activePanels.value = [] // Pour ne garder que un panel ouvert à la fois
        activePanels.value.push(panel)
      }
    }

    return {
      dayjs,
      convertRegister,
      convertUnit,
      trimzerosIndex,
      cadran,
      myI18n: myState.i18n,
      communeI18n: communeState.i18n,
      breadcrumbPage,
      indexData,
      adresses,
      authorizations,
      authorizationTypes,
      releveIndexUrl,
      ean,
      compteur,
      changeMeter,
      isLoading,
      exportDatas,
      togglePanel,
      activePanels,
      check,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
