var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.communeI18n && _vm.myI18n)?_c('div',{staticClass:"meter-history"},[(_vm.adresses && _vm.adresses.length > 0)?_c('adresse-switcher',{attrs:{"datas":_vm.adresses,"ean":_vm.ean,"meter":_vm.compteur},on:{"changeMeter":_vm.changeMeter}}):_vm._e(),_c('breadcrumb',{attrs:{"content":_vm.breadcrumbPage}}),_c('div',{staticClass:"wrapper mb-xl"},[(_vm.communeI18n.communeIndex.picture)?_c('img',{staticClass:"page-header-image",attrs:{"src":_vm.apiDomain + _vm.communeI18n.communeIndex.picture.url,"alt":""}}):_c('img',{staticClass:"page-header-image",attrs:{"alt":"","src":require("../../../assets/svg/commune/ico-index.svg")}}),_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__title"},[_c('h1',{staticClass:"main-title",domProps:{"innerHTML":_vm._s(_vm.communeI18n.communeIndex.title)}}),_c('BlockTips',{attrs:{"content":_vm.communeI18n.relevesIndex.tips.content,"title":_vm.communeI18n.relevesIndex.tips.title}})],1),_c('div',{staticClass:"page-header__actions"},[(
            _vm.authorizations.includes(_vm.authorizationTypes.INDEX_GERER) ||
            _vm.authorizations.includes(_vm.authorizationTypes.ALL)
          )?_c('div',{staticClass:"btn-area"},[_c('CustomLink',{staticClass:"add-index",attrs:{"content":{
              label: _vm.communeI18n.communeIndex.labelButton,
              url: _vm.releveIndexUrl,
              target: '_blank',
            },"modifiers":['btn', 'big', 'rectrounded', 'uppercase'],"type":'externalLink'}})],1):_vm._e(),_c('vue-json-to-csv',{staticClass:"export",attrs:{"csv-title":_vm.check.formatExportTitle(_vm.communeI18n.export.historyIndex),"json-data":_vm.exportDatas,"labels":{
            numCpt: { title: _vm.myI18n.meterHistory.meterNumber },
            date: { title: _vm.myI18n.meterHistory.date },
            index: { title: _vm.myI18n.meterHistory.reading },
            unite: { title: _vm.myI18n.meterHistory.unit },
            cadran: { title: _vm.myI18n.meterHistory.cadran },
          },"separator":';'}},[_c('CustomLink',{staticClass:"export-compteur",attrs:{"content":{
              label: _vm.communeI18n.relevesIndex.export.bouton,
              url: '#',
              icon: {
                size: '0 0 24 24',
                name: 'commune-file_download',
              },
            },"modifiers":[
              'btn',
              'big',
              'green',
              'rectrounded',
              'outline',
              'uppercase' ],"type":'externalLink'}})],1)],1)]),(!_vm.isLoading)?_c('div',{staticClass:"mt-s mb-xl"},_vm._l((_vm.indexData),function(data,index){return _c('panel-accordion',{key:index,staticClass:"mb-s",attrs:{"content":{
          title: _vm.myI18n.meterHistory.meterNumber + ' : ' + data[0].numCpt,
          isActive: _vm.activePanels.indexOf(index) >= 0,
        }},on:{"toggle":function($event){return _vm.togglePanel(index)}}},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"table__th small"},[_vm._v(_vm._s(_vm.myI18n.meterHistory.date))]),_c('th',{staticClass:"table__th small"},[_vm._v(" "+_vm._s(_vm.myI18n.meterHistory.cadran)+" ")]),_c('th',{staticClass:"table__th small"},[_vm._v(" "+_vm._s(_vm.myI18n.meterHistory.reading)+" ")]),_c('th',{staticClass:"table__th small"},[_vm._v(_vm._s(_vm.myI18n.meterHistory.unit))])])]),_c('tbody',_vm._l((data),function(releve,index){return _c('tr',{key:index},[_c('td',{staticClass:"table__td small"},[_vm._v(" "+_vm._s(_vm.dayjs(releve.date).format('DD/MM/YYYY'))+" ")]),_c('td',{staticClass:"table__td small"},[_vm._v(" "+_vm._s(_vm.convertRegister(releve.cadran))+" ")]),_c('td',{staticClass:"table__td small"},[_vm._v(" "+_vm._s(_vm.trimzerosIndex(releve.index))+" ")]),_c('td',{staticClass:"table__td small"},[_vm._v(" "+_vm._s(_vm.convertUnit(releve.unite))+" ")])])}),0)])])}),1):_c('g-loader',{staticClass:"history-cpt-loader mt-s",attrs:{"modifiers":['big']}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }